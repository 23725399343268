import { useCallback } from 'react';

import Button, { ButtonAppearance, ButtonIconPosition } from 'bloko/blocks/button';
import DropMenu from 'bloko/blocks/drop/Menu';
import { ChevronScaleSmallKindDown } from 'bloko/blocks/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ChangeTopicMenuItem, { SendAnalyticsHandler } from 'src/components/ChangeTopicMenuItem';
import translation from 'src/components/translation';
import useToggleState from 'src/hooks/useToggleState';
import { NegotiationLink } from 'src/models/employerNegotiations/negotiationLinks';

const TrlKeys = {
    buttonText: 'vacancyResponse.discard',
};

interface ChangeTopicButtonProps {
    host?: HTMLElement | null;
    appearance?: ButtonAppearance;
    links: NegotiationLink[];
    showIcon?: boolean;
    stretched?: boolean;
    sendAnalytics?: SendAnalyticsHandler;
    dataQa?: string;
}

const ChangeTopicButton: TranslatedComponent<ChangeTopicButtonProps> = ({
    trls,
    host,
    links,
    appearance,
    showIcon,
    stretched,
    sendAnalytics,
    dataQa,
}) => {
    const [isOpen, toggleOpenState, setOpenState] = useToggleState(false);

    const handleClose = useCallback(() => setOpenState(false), [setOpenState]);

    return (
        <DropMenu
            host={host}
            show={isOpen}
            onClose={handleClose}
            render={() =>
                links.map(({ name, url }) => (
                    <ChangeTopicMenuItem key={name} name={name} url={url} sendAnalytics={sendAnalytics} />
                ))
            }
        >
            <Button
                appearance={appearance}
                stretched={stretched}
                {...(showIcon && {
                    icon: <ChevronScaleSmallKindDown />,
                    iconPosition: ButtonIconPosition.Right,
                })}
                onClick={toggleOpenState}
                data-qa={dataQa}
            >
                {trls[TrlKeys.buttonText]}
            </Button>
        </DropMenu>
    );
};

export default translation(ChangeTopicButton);
export type { SendAnalyticsHandler };
