import { DefaultRootState } from 'react-redux';

import { selectResumeStringField } from 'src/models/applicant/resume/selectors';

import { Phone, PreferredContact, PersonalSite } from 'src/models/applicant/resume/blocks/contacts/types';

export const selectApplicantResumeEmail = selectResumeStringField('email', '');

export const selectApplicantResumePreferredContact = selectResumeStringField<PreferredContact>(
    'preferredContact',
    PreferredContact.CellPhone
);

export const selectApplicantResumePhone = ({ applicantResume }: DefaultRootState): Phone[] =>
    applicantResume?.phone ?? [];

export const selectApplicantResumePersonalSite = ({ applicantResume }: DefaultRootState): PersonalSite[] =>
    applicantResume?.personalSite ?? [];
