export enum PhoneType {
    Cell = 'cell',
    Home = 'home',
    Work = 'work',
}

export enum PreferredContact {
    CellPhone = 'cell_phone',
    HomePhone = 'home_phone',
    WorkPhone = 'work_phone',
    Email = 'email',
}

export interface Phone {
    city: string;
    comment: string;
    country: string;
    type: PhoneType;
    verified: boolean;
    number: string | null;
    formatted: string;
    raw: string;
    needVerification: boolean;
}

export type SocialSiteTypes = 'freelance' | 'linkedin' | 'skype' | 'livejournal' | 'moi_krug' | 'facebook';

export type PersonalSiteTypes = SocialSiteTypes | 'personal';

export interface PersonalSite {
    type: PersonalSiteTypes;
    url: string;
}

export interface ConvertedSite<T = PersonalSiteTypes> {
    type: T;
    url?: string;
    text: string;
}
