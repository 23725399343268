/* eslint-disable camelcase */

export enum CareerBannerPosition {
    Resume = 'resume',
    Profile = 'profile',
    Search = 'search',
    Negotiations = 'negotiations',
}
export enum CareerLinksKeys {
    Analytic = 'link_analytic',
    Calc = 'link_calc',
    CareerTrack = 'link_career_track',
    Courses = 'link_courses',
    Catalog = 'https://career.ru/courses',
}
export interface CareerPlatformLinks {
    [CareerLinksKeys.Analytic]: string;
    [CareerLinksKeys.Calc]: string;
    [CareerLinksKeys.CareerTrack]: string;
    [CareerLinksKeys.Courses]: string;
    profession_name: string;
}
export interface CareerPlatformState {
    links: CareerPlatformLinks | null;
    is_role: boolean;
    resume_hash: string | undefined;
    negotiation_id: number | undefined;
}
