import { ComponentType } from 'react';

import { useSelector } from '@hh.ru/front-static-app';
import { Text, Link, Cell, useBreakpoint } from '@hh.ru/magritte-ui';
import { ExclamationTriangleOutlinedSize24, ExclamationTriangleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import translation from 'src/components/translation';
import { getPreferredContactType } from 'src/models/applicant/resume/blocks/contacts/lib';
import {
    selectApplicantResumePhone,
    selectApplicantResumePreferredContact,
} from 'src/models/applicant/resume/blocks/contacts/selectors';
import { EM_DASH, MIDDLE_DOT, NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    phone: {
        cell: 'applicant.resume.contacts.field.phone.cell',
        home: 'applicant.resume.contacts.field.phone.home',
        work: 'applicant.resume.contacts.field.phone.work',
    },
    preferredContact: 'applicant.resume.contacts.preferredContact.hint',
    nonVerified: 'applicant.resume.contacts.non.verified.hint',
};

const ContactsPhones: ComponentType = translation(({ trls }) => {
    const phones = useSelector(selectApplicantResumePhone);
    const preferredContact = useSelector(selectApplicantResumePreferredContact);

    const { isMobile } = useBreakpoint();

    const getPhoneVerificationHint = (needVerification: boolean, isPreferred: boolean) => {
        if (needVerification) {
            return (
                <>
                    {!isMobile && <>{`${NON_BREAKING_SPACE}${MIDDLE_DOT}${NON_BREAKING_SPACE}`}</>}
                    <Text Element="span" style="warning" typography="label-3-regular">
                        {trls[TrlKeys.nonVerified]}
                    </Text>
                </>
            );
        }
        if (isPreferred) {
            return (
                <>
                    {!isMobile && <>{`${NON_BREAKING_SPACE}${EM_DASH}${NON_BREAKING_SPACE}`}</>}
                    {trls[TrlKeys.preferredContact]}
                </>
            );
        }
        return null;
    };

    return (
        <>
            {phones.map(({ type, formatted, raw, needVerification }) => {
                const isPreferred = getPreferredContactType(preferredContact) === type;

                return (
                    <div key={type} data-qa="resume-serp_resume-item-content">
                        <div data-qa="resume-contacts-phone">
                            <Cell
                                left={
                                    !isMobile && needVerification ? (
                                        <ExclamationTriangleOutlinedSize24 initial="warning" />
                                    ) : undefined
                                }
                                right={
                                    isMobile && needVerification ? (
                                        <ExclamationTriangleOutlinedSize16 initial="warning" />
                                    ) : undefined
                                }
                            >
                                <Text style="secondary" typography="label-3-regular">
                                    {trls[TrlKeys.phone[type]]}
                                    {isMobile && <br />}
                                    {getPhoneVerificationHint(needVerification, isPreferred)}
                                </Text>
                                <Link
                                    style="neutral"
                                    typography="label-2-regular"
                                    href={`tel:+${raw}`}
                                    data-qa={isPreferred ? 'resume-contact-preferred' : undefined}
                                >
                                    {formatted}
                                </Link>
                            </Cell>
                        </div>
                    </div>
                );
            })}
        </>
    );
});

export default ContactsPhones;
