import { DefaultRootState } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { ResumeApi } from 'src/models/applicant/resume/api';
import { isConditionsErrors, isSuccess } from 'src/models/applicant/resume/api/lib';
import { editorConfigMap } from 'src/models/applicant/resume/editor/configMap';
import { fromApiToLocalErrors } from 'src/models/applicant/resume/editor/form/errors';
import { EditorFormType, UpdateBodyType } from 'src/models/applicant/resume/editor/types/config';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types/fields';
import { isEmpty } from 'src/models/applicant/resume/lib/common/empty';
import { selectHhtmSource, selectResumeHash } from 'src/models/applicant/resume/selectors';
import { setApplicantResumeFields } from 'src/models/applicant/resume/slice';

import {
    selectResumeCondition,
    selectResumeEditorType,
    selectResumeEditorSaving,
    selectResumeEditorFields,
} from 'src/models/applicant/resume/editor/store/selectors';
import {
    setResumeEditorType,
    setResumeEditorFields,
    toggleResumeEditorSaving,
    setResumeEditorFormErrors,
    toggleResumeEditorConfirm,
    toggleResumeEditorLoading,
    resetResumeEditorFormErrors,
    toggleResumeEditorVisibility,
    setResumeEditorDictionaries,
    showNotification,
} from 'src/models/applicant/resume/editor/store/slice';

export type Thunk<R = Promise<void>> = ThunkAction<R, DefaultRootState, unknown, Action>;

export const openResumeEditorModal =
    (type: EditorFormType) =>
    async (dispatch: Dispatch, getState: () => DefaultRootState): Promise<void> => {
        const saving = selectResumeEditorSaving(getState());
        if (saving) {
            return;
        }

        const config = editorConfigMap[type];

        dispatch(setResumeEditorType({ type }));
        dispatch(resetResumeEditorFormErrors());

        dispatch(toggleResumeEditorVisibility(true));
        if (config.preload) {
            dispatch(toggleResumeEditorLoading(true));
            try {
                const dictionaries = await config.preload(getState(), dispatch);
                dispatch(setResumeEditorDictionaries(dictionaries));
            } catch (_) {
                dispatch(toggleResumeEditorLoading(false));
                dispatch(toggleResumeEditorVisibility(false));
                dispatch(showNotification('error-api'));
                // mb sentry log
            }
        }

        dispatch(setResumeEditorFields(config.init(getState())));
        dispatch(toggleResumeEditorLoading(false));
    };

export const saveResumeEditorData =
    (): Thunk =>
    async (dispatch: Dispatch, getState: () => DefaultRootState): Promise<void> => {
        const saving = selectResumeEditorSaving(getState());
        if (saving) {
            return;
        }

        const type = selectResumeEditorType(getState());
        const config = editorConfigMap[type];

        if (!config.changed(getState())) {
            dispatch(toggleResumeEditorVisibility(false));
            return;
        }

        const fields = selectResumeEditorFields(getState()) as Required<ApplicantResumeEditorFields>;
        const conditions = selectResumeCondition(getState());

        const body = config.prepare(fields) as Required<UpdateBodyType>;

        const errors = config.validate(body, conditions);

        if (config.beforeSubmit) {
            config.beforeSubmit(getState(), errors);
        }

        if (!isEmpty(errors)) {
            dispatch(setResumeEditorFormErrors(errors));
            return;
        }

        dispatch(toggleResumeEditorSaving(true));

        try {
            const resumeHash = selectResumeHash(getState());
            const hhtmSource = selectHhtmSource(getState());

            const response = await ResumeApi.update(resumeHash, hhtmSource, body);

            if (isSuccess(response)) {
                dispatch(setApplicantResumeFields(body));
                dispatch(toggleResumeEditorVisibility(false));
                dispatch(showNotification('success-save'));
                return;
            }

            if (isConditionsErrors(response)) {
                const errors = fromApiToLocalErrors(response.errors);
                dispatch(setResumeEditorFormErrors(errors));
                return;
            }

            dispatch(showNotification('error-api'));
            // mb sentry log
        } catch (_) {
            dispatch(showNotification('error-api'));
            // mb sentry log
        } finally {
            dispatch(toggleResumeEditorSaving(false));
        }
    };

export const closeResumeEditorModal =
    () =>
    (dispatch: Dispatch, getState: () => DefaultRootState): void => {
        const state = getState();
        const type = selectResumeEditorType(state);
        const saving = selectResumeEditorSaving(state);

        if (saving) {
            return;
        }
        const config = editorConfigMap[type];

        if (config.changed(state)) {
            void dispatch(toggleResumeEditorConfirm(true));
        } else {
            dispatch(toggleResumeEditorVisibility(false));
        }
    };
