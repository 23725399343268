import { connect } from 'react-redux';

import HoverTip, { TipPlacement } from 'bloko/blocks/drop/Tip/HoverTip';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { AppStore } from 'src/app/store';
import translation from 'src/components/translation';
import { Platform } from 'src/models/locale.types';

const TrlKeys = {
    badgeText: 'search.resume.crossPostedLabel.fromZP',
    hoverTip: 'resume.crossposting.badge.hoverTip',
};

interface ResumeCrosspostedBadge {
    hasPhoto: boolean;
    isCrossposted: boolean;
}

const ResumeCrosspostedBadge: TranslatedComponent<ResumeCrosspostedBadge> = ({ trls, hasPhoto, isCrossposted }) => {
    if (!isCrossposted) {
        return null;
    }

    return (
        <>
            {hasPhoto && <VSpacing base={4} xs={0} />}
            <HoverTip render={() => <Text>{trls[TrlKeys.hoverTip]}</Text>} placement={TipPlacement.Bottom}>
                <div className="resume-header-source-badge">
                    <Text>{trls[TrlKeys.badgeText]}</Text>
                </div>
            </HoverTip>
            <VSpacing base={2} xs={0} />
        </>
    );
};

export default connect((state: AppStore) => ({
    hasPhoto: !!state.resume?.photoUrls?.value?.length,
    isCrossposted: state.resume?.sitePlatform === Platform.Zarplata && state.locale.platform === Platform.HeadHunter,
}))(translation(ResumeCrosspostedBadge));
