import { ComponentType, useMemo } from 'react';

import { useSelector } from '@hh.ru/front-static-app';
import { GridColumn, GridRow, VSpacingContainer } from '@hh.ru/magritte-ui';
import { format } from 'bloko/common/trl';

import DefaultLayout from 'src/app/layouts/MagritteLayouts/DefaultLayout';
import PageLayout from 'src/app/layouts/PageLayout';
import translation from 'src/components/translation';
import { selectApplicantResumeTitle } from 'src/models/applicant/resume/blocks/position/selectors';

import ApplicantResumeAbout from 'src/pages/ResumeView/redesign/about/Card';
import EditorCloseConfirm from 'src/pages/ResumeView/redesign/common/Confirm';
import Notifications from 'src/pages/ResumeView/redesign/common/Notifications';
import ApplicantResumeContacts from 'src/pages/ResumeView/redesign/contacts/Card';
import ApplicantResumePosition from 'src/pages/ResumeView/redesign/position/Card';

const TrlKeys = {
    newResume: 'rb.view.newresume',
    pageTitle: 'applicant.resume.page.title',
};

const ResumeView: ComponentType = translation(({ trls }) => {
    const title = useSelector(selectApplicantResumeTitle);

    const trlTitle = useMemo(() => {
        if (title !== '') {
            return format(trls[TrlKeys.pageTitle], { '{0}': title });
        }

        return trls[TrlKeys.newResume];
    }, [title, trls]);

    return (
        <PageLayout title={trlTitle} layout={DefaultLayout}>
            <GridRow>
                <GridColumn xs={4} s={8} m={8} l={8} xl={8} xxl={8}>
                    <VSpacingContainer default={32}>
                        <ApplicantResumePosition />
                        <ApplicantResumeContacts />
                        <ApplicantResumeAbout />
                    </VSpacingContainer>
                </GridColumn>
            </GridRow>
            <EditorCloseConfirm />
            <Notifications />
        </PageLayout>
    );
});

export default ResumeView;
