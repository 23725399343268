import { ComponentType } from 'react';

import { Card, VSpacingContainer, Text } from '@hh.ru/magritte-ui';

import translation from 'src/components/translation';

import EditorButton from 'src/pages/ResumeView/redesign/contacts/Card/EditorButton';
import ContactsEmail from 'src/pages/ResumeView/redesign/contacts/Card/Email';
import ContactsPhones from 'src/pages/ResumeView/redesign/contacts/Card/Phones';
import ContactsSocialSites from 'src/pages/ResumeView/redesign/contacts/Card/SocialSites';

import styles from './styles.less';

const TrlKeys = {
    label: 'applicant.resume.contacts.label',
};

const ApplicantResumeContacts: ComponentType = translation(({ trls }) => {
    return (
        <VSpacingContainer default={12}>
            <Text typography="label-2-regular" style="secondary">
                {trls[TrlKeys.label]}
            </Text>
            <Card stretched padding={24} borderRadius={24} showBorder>
                <div className={styles.card}>
                    <VSpacingContainer default={16}>
                        <ContactsPhones />
                        <ContactsEmail />
                        <ContactsSocialSites />
                    </VSpacingContainer>
                    <EditorButton onClick={() => undefined} />
                </div>
            </Card>
        </VSpacingContainer>
    );
});

export default ApplicantResumeContacts;
