import { DefaultRootState } from 'react-redux';

type StringFieldKeys = 'title' | 'gender' | 'lastName' | 'firstName' | 'email' | 'preferredContact' | 'skills';

export const selectResumeHash = ({ applicantResume }: DefaultRootState): string => applicantResume._attributes.hash;
export const selectHhtmSource = ({ analyticsParams }: DefaultRootState): string => analyticsParams.hhtmSource;
export const selectResumeId = ({ applicantResume }: DefaultRootState): string => applicantResume._attributes.id;
export const selectVacancyId = ({ router }: DefaultRootState): string => router.location.query.vacancyId;

export const selectResumeStringField =
    <T>(key: StringFieldKeys, defaultValue: T) =>
    ({ applicantResume }: DefaultRootState): T => {
        return (applicantResume?.[key]?.[0]?.string as T) ?? defaultValue;
    };
