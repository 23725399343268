import Button from 'bloko/blocks/button';
import MenuItem from 'bloko/blocks/drop/Menu/Item';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ResumeShareModal from 'src/components/HiringManager/ResumeShareModal';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    share: 'employer.resume.button.share',
};

interface ShareResumeProps {
    type: 'button' | 'menuItem';
}

const ShareResume: TranslatedComponent<ShareResumeProps> = ({ trls, type }) => {
    const [isModalVisible, setOn, setOff] = useOnOffState(false);
    const { t: topicId } = useSelector((state) => state.router.location.query);
    const resume = useSelector((state) => state.resume);
    const resumeHash = resume?.hash;
    const resumeName = resume?.title.value;
    const control =
        type === 'button' ? (
            <Button onClick={setOn}>{trls[TrlKeys.share]}</Button>
        ) : (
            <MenuItem onClick={setOn}>{trls[TrlKeys.share]}</MenuItem>
        );

    if (!resumeHash || !resumeName) {
        return null;
    }

    return (
        <>
            {control}
            <ResumeShareModal
                topicIds={[topicId]}
                resumeName={[resumeName]}
                resumeHash={[resumeHash]}
                visible={isModalVisible}
                setOff={setOff}
            />
        </>
    );
};

export default translation(ShareResume);
