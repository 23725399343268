import { FC, PropsWithChildren } from 'react';

import { CheckableCard, Text } from '@hh.ru/magritte-ui';

import styles from './index.less';

const CenteredText: FC<PropsWithChildren> = ({ children }) => <div className={styles.text}>{children}</div>;

const CheckablePlate: FC<
    PropsWithChildren<{
        name: string;
        checked: boolean;
    }>
> = ({ name, checked, children }) => (
    <CheckableCard type="radio" borderRadius={12} padding={16} checked={checked} name={name}>
        <Text Element={CenteredText} typography="label-3-regular">
            {children}
        </Text>
    </CheckableCard>
);

export default CheckablePlate;
