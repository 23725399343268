import useExperiment from 'src/hooks/useExperiment';

import ResumeViewOld from 'src/pages/ResumeView/old/ResumeView';
import ResumeViewNew from 'src/pages/ResumeView/redesign/ResumeView';

const ResumeView = (props) => {
    const isMagritteExp = useExperiment('magritte_applicant_resume_view');
    if (isMagritteExp) {
        return <ResumeViewNew />;
    }
    return <ResumeViewOld {...props} />;
};

export default ResumeView;
