import { useMemo } from 'react';

import { LangTrls, useSelector } from '@hh.ru/front-static-app';
import getConversionType from 'bloko/common/getConversionType';

import { selectResumeEditorErrors } from 'src/models/applicant/resume/editor/store/selectors';
import { EditorFieldError, EditorFormErrors } from 'src/models/applicant/resume/editor/types/error';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types/fields';
import { dateFromDateTypeToFormFormat } from 'src/models/applicant/resume/lib/common/date';
import { hasOwn, keys } from 'src/models/applicant/resume/lib/common/object';
import { Condition } from 'src/models/applicant/resume/types/conditions';

export interface PluralError {
    one: string;
    some: string;
    many: string;
}

type ErrorValue = string | PluralError;

export type ErrorsMap = Record<string, ErrorValue>;

export const useEditorError = <Key extends keyof ApplicantResumeEditorFields>(
    key: Key,
    errorsMap: ErrorsMap = {},
    value?: Required<ApplicantResumeEditorFields>[Key],
    condition?: Condition,
    diffCountErrorKeys?: string[]
): {
    invalid: boolean;
    errorMessage: string | undefined;
} => {
    const formErrors = useSelector(selectResumeEditorErrors);

    const errorMessage = useMemo(() => {
        const code = formErrors?.[key]?.[0]?.code;
        if (!code) {
            return undefined;
        }

        const errorValue = errorsMap[code];

        const conditionText = getConditionText(code, condition);

        const valueAttribute = Number(
            (code.startsWith('LENGTH') || code.startsWith('SIZE')) &&
                value &&
                (typeof value === 'string' || (typeof value === 'object' && 'length' in value))
                ? value?.length
                : value
        );

        const conditionParameter = (diffCountErrorKeys ?? []).includes(code)
            ? String(valueAttribute - Number(conditionText))
            : conditionText;

        const baseText =
            typeof errorValue === 'string' ? errorValue : getConversionType({ ...errorValue, value: valueAttribute });

        if (!baseText) {
            const unknownError = errorsMap.UNKNOWN;
            if (unknownError) {
                return unknownError as string;
            }
            return '';
        }
        return `${baseText}`.replace('{0}', conditionParameter);
    }, [formErrors, key, errorsMap, condition, value, diffCountErrorKeys]);

    return { invalid: !!errorMessage, errorMessage };
};

export function getConditionText(code: string, condition?: Condition): string {
    if (code === 'SIZE_LESS_THAN_MINIMUM') {
        return condition?.mincount ? `${condition.mincount}` : '';
    } else if (code === 'SIZE_GREATER_THAN_MAXIMUM') {
        return condition?.maxcount ? `${condition.maxcount}` : '';
    } else if (code === 'VALUE_LESS_THAN_MINIMUM') {
        return condition?.mincount ? `${condition.mincount}` : '';
    } else if (code === 'VALUE_GREATER_THAN_MAXIMUM') {
        return condition?.maxcount ? `${condition.maxcount}` : '';
    } else if (code === 'LENGTH_GREATER_THAN_MAXIMUM') {
        return String(condition?.parts.find((part) => 'string' in part)?.string.maxlength);
    } else if (code === 'EARLY_THAN_MINIMUM') {
        const value = condition?.parts.find((part) => 'date' in part)?.date.minvalue;
        return value ? dateFromDateTypeToFormFormat(value) : '';
    } else if (code === 'LATER_THAN_MAXIMUM') {
        const value = condition?.parts.find((part) => 'date' in part)?.date.maxvalue;
        return value ? dateFromDateTypeToFormFormat(value) : '';
    }
    return '';
}

export const useTranslatedErrorMap = (keysMap: ErrorsMap, trls: LangTrls): ErrorsMap => {
    return useMemo(
        () =>
            keys(keysMap).reduce((prev, key) => {
                if (typeof keysMap[key] === 'string') {
                    return { ...prev, [key]: trls[keysMap[key]] };
                }
                return {
                    ...prev,
                    [key]: {
                        one: trls[keysMap[key].one],
                        some: trls[keysMap[key].some],
                        many: trls[keysMap[key].many],
                    },
                };
            }, {}),
        [keysMap, trls]
    );
};

/**
 * Бэк отдает массив ошибок.
 * Преобразовываем в мапу формата Record<FieldName, Error[]>
 */
export function fromApiToLocalErrors(errors: EditorFieldError[]): EditorFormErrors {
    return errors.reduce((acc, error) => {
        const field = error.field;
        if (hasOwn(acc, field)) {
            acc[field].push(error);
            return acc;
        }
        return { ...acc, [error.field]: [error] };
    }, {} as EditorFormErrors);
}
