import {
    PreferredContact,
    PhoneType,
    PersonalSite,
    ConvertedSite,
    SocialSiteTypes,
} from 'src/models/applicant/resume/blocks/contacts/types';

export const EMAIL = 'email';

const preferredToContactType = {
    [PreferredContact.Email]: EMAIL,
    [PreferredContact.CellPhone]: PhoneType.Cell,
    [PreferredContact.HomePhone]: PhoneType.Home,
    [PreferredContact.WorkPhone]: PhoneType.Work,
};

export const getPreferredContactType = (preferredContact: PreferredContact): string | null => {
    if (preferredContact in preferredToContactType) {
        return preferredToContactType[preferredContact];
    }
    return null;
};

const socialSiteRegexMap = {
    freelance: /(?:https?:\/\/)?(?:www\.)?(?:fl\.ru|free-lance\.ru)\/users\/(.*)$/,
    livejournal: /(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9_-]+)\.livejournal\.com\/?$/,
    linkedin: /(?:https?:\/\/)(?:www\.)?linkedin\.com\/(.*)$/,
    // eslint-disable-next-line camelcase
    moi_krug: /(?:https?:\/\/)(?:www\.)?career\.habr\.(?:ru|com)\/(.*)$/,
    facebook: /(?:https?:\/\/)(?:www\.)?(?:facebook|fb|m\.facebook)\.(?:com|me)\/(.*)$/,
} as const;

export const getConvertedPersonalSites = (personalSites: PersonalSite[]): ConvertedSite[] => {
    return personalSites.map((site) => {
        if (site.type === 'personal') {
            return { ...site, text: site.url };
        }
        if (site.type === 'skype') {
            return { type: site.type, text: site.url };
        }
        return {
            ...site,
            text: site.url.match(socialSiteRegexMap[site.type])?.[1] ?? '',
        };
    });
};

export const getSeparatedSocialAndPersonalSites = (
    convertedSites: ConvertedSite[]
): [ConvertedSite | undefined, ConvertedSite<SocialSiteTypes>[]] => {
    const personalSite = convertedSites.find(({ type }) => type === 'personal');
    const sites = convertedSites.filter(({ type }) => type !== 'personal') as ConvertedSite<SocialSiteTypes>[];
    const withUrl = sites.filter(({ url }) => !!url);
    const withoutUrl = sites.filter(({ url }) => !url);
    return [personalSite, [...withUrl, ...withoutUrl]];
};
