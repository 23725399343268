import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from '@hh.ru/front-static-app';

import { ErrorsMap, useEditorError } from 'src/models/applicant/resume/editor/form/errors';
import {
    selectResumeEditorSaving,
    selectResumeConditionByKey,
} from 'src/models/applicant/resume/editor/store/selectors';
import { setResumeEditorFieldValue, clearResumeEditorErrorByKey } from 'src/models/applicant/resume/editor/store/slice';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types/fields';
import { Condition } from 'src/models/applicant/resume/types/conditions';

type FieldData<Key extends keyof ApplicantResumeEditorFields> = {
    value: Required<ApplicantResumeEditorFields>[Key];
    invalid: boolean;
    onChange: (value: ApplicantResumeEditorFields[Key], resetError?: boolean) => void;
    disabled: boolean;
    condition?: Condition;
    errorMessage: string | undefined;
};

export const useResumeEditorField = <Key extends keyof ApplicantResumeEditorFields>(
    key: Key,
    errorsMap: ErrorsMap = {},
    diffCountErrorKeys: string[] = []
): FieldData<Key> => {
    const dispatch = useDispatch();
    const saving = useSelector(selectResumeEditorSaving);
    const condition = useSelector(selectResumeConditionByKey(key));

    const value = useSelector(
        ({ resumeEditor }) => resumeEditor.fields[key] as Required<ApplicantResumeEditorFields>[Key]
    );

    const { invalid, errorMessage } = useEditorError(key, errorsMap, value, condition, diffCountErrorKeys);

    const onChange = useCallback(
        (value: ApplicantResumeEditorFields[Key], resetError = true) => {
            dispatch(setResumeEditorFieldValue({ [key]: value }));
            if (resetError) {
                dispatch(clearResumeEditorErrorByKey(key));
            }
        },
        [dispatch, key]
    );

    return {
        value,
        invalid,
        onChange,
        disabled: saving,
        condition,
        errorMessage,
    };
};
