import { useRef, useState, useEffect, useLayoutEffect, ReactNode, useCallback, useMemo, RefObject } from 'react';

import { Button as MagritteButton, ActionList, ActionListProps, useBreakpoint } from '@hh.ru/magritte-ui';
import { EllipsisHorizontalOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import Button from 'bloko/blocks/button';
import Menu, { MenuLayer, MenuPlacement } from 'bloko/blocks/drop/Menu';
import { EllipsisScaleSmallKindVertical } from 'bloko/blocks/icon';
import VSpacingContainer from 'bloko/blocks/vSpacing/VSpacingContainer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useShownRawAnalyticsWithInnerButtons } from 'src/hooks/useMyVacanciesAnalytics';
import { useSelector } from 'src/hooks/useSelector';
import useToggleState from 'src/hooks/useToggleState';

interface AdaptiveButtonsProps {
    host?: HTMLElement | null;
    buttons: ReactNode[];
    menuItems: ({ onClose }: { onClose: () => void }) => ReactNode[];
    withEmployerVacanciesAnalytics?: boolean;
    redesign?: boolean;
    customContainerRef?: RefObject<HTMLElement>;
}

const TrlKeys = {
    showMore: 'adaptiveButtons.showMore',
};

const AdaptiveButtons: TranslatedComponent<AdaptiveButtonsProps> = ({
    trls,
    host,
    buttons,
    menuItems,
    withEmployerVacanciesAnalytics = false,
    redesign = false,
    customContainerRef = null,
}) => {
    const isHiringManagerExperiment = useSelector((state) => state.isHiringManagerExperiment);
    const { isMobile } = useBreakpoint();
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [isCalculationInProgress, setIsCalculationInProgress] = useState(true);

    useEffect(() => {
        setIsFirstRender(false);
    }, []);

    const buttonsFiltered = useMemo(() => (isFirstRender ? [] : buttons.filter(Boolean)), [isFirstRender, buttons]);

    const containerRef = useRef<HTMLDivElement>(null);
    const buttonsRef = useRef<HTMLSpanElement[]>([]);
    const menuButtonRef = useRef<HTMLSpanElement>(null);
    const menuButtonsRef = useRef<HTMLDivElement>(null);
    const actionListActivatorRef = useRef<HTMLButtonElement>(null);

    const [containerWidth, setContainerWidth] = useState(0);
    const [buttonsWidths, setButtonsWidths] = useState<number[]>([]);
    const [buttonsShown, setButtonsShown] = useState(buttonsFiltered.length);
    const [menuIsOpen, toggleMenuIsOpen, setMenuIsOpen] = useToggleState(false);
    const closeDrop = useCallback(() => setMenuIsOpen(false), [setMenuIsOpen]);

    const shownButtonsRawAnalytics = useShownRawAnalyticsWithInnerButtons(customContainerRef || containerRef);
    const shownMenuRawAnalytics = useShownRawAnalyticsWithInnerButtons(menuButtonsRef);

    useEffect(() => {
        if (withEmployerVacanciesAnalytics) {
            shownButtonsRawAnalytics('employer_vacancies_vacancy_button_bar');
        }
    }, [shownButtonsRawAnalytics, withEmployerVacanciesAnalytics]);

    const startCalculationForWindowResize = useCallback(() => {
        setIsCalculationInProgress(true);
    }, []);

    useLayoutEffect(() => {
        window.addEventListener('resize', startCalculationForWindowResize);
        setIsCalculationInProgress(true);
        return () => window.removeEventListener('resize', startCalculationForWindowResize);
    }, [startCalculationForWindowResize]);

    useLayoutEffect(() => {
        setIsCalculationInProgress(true);
    }, [buttonsFiltered]);

    useLayoutEffect(() => {
        if (isCalculationInProgress) {
            setContainerWidth((customContainerRef || containerRef).current?.getBoundingClientRect().width || 0);
            setButtonsWidths(buttonsRef.current?.map((element) => element.getBoundingClientRect().width) || 0);
        }
    }, [customContainerRef, isCalculationInProgress]);

    useLayoutEffect(() => {
        const menuButtonLeftOffset = 4;
        const menuButtonWidth = (menuButtonRef.current?.getBoundingClientRect().width || 0) + menuButtonLeftOffset;

        let sumWidth = 0;
        let newButtonsShowed = 0;

        for (const width of buttonsWidths) {
            const requiredWidthWithOneMoreButton = sumWidth + width + menuButtonWidth;

            if (requiredWidthWithOneMoreButton > containerWidth) {
                break;
            }
            newButtonsShowed += 1;
            sumWidth += width;
        }
        setButtonsShown(newButtonsShowed);
        setIsCalculationInProgress(false);
    }, [buttonsWidths, containerWidth]);

    const toggleMenu = () => {
        if (withEmployerVacanciesAnalytics) {
            shownMenuRawAnalytics('employer_vacancies_vacancy_button_bar_menu');
        }
        toggleMenuIsOpen();
    };

    const dropProps = useMemo<ActionListProps['dropProps']>(
        () => ({
            activatorRef: actionListActivatorRef,
            placement: 'bottom-left',
            role: 'status',
        }),
        []
    );

    const menuElements = menuItems({ onClose: closeDrop }).filter(Boolean).slice(buttonsShown);
    const oldMenu =
        isMobile && isHiringManagerExperiment ? (
            <>
                <Button
                    onClick={toggleMenu}
                    icon={<EllipsisScaleSmallKindVertical />}
                    data-qa="adaptive-buttons-menu-button"
                />
                <ActionList visible={menuIsOpen} onClose={closeDrop} dropProps={dropProps}>
                    <VSpacingContainer base={2}>{menuElements.map((element) => element)}</VSpacingContainer>
                </ActionList>
            </>
        ) : (
            <Menu
                host={host}
                show={menuIsOpen}
                layer={MenuLayer.Overlay}
                onClose={closeDrop}
                placement={MenuPlacement.BottomEnd}
                render={() => <div ref={menuButtonsRef}>{menuElements}</div>}
            >
                <Button
                    onClick={toggleMenu}
                    icon={<EllipsisScaleSmallKindVertical />}
                    data-qa="adaptive-buttons-menu-button"
                />
            </Menu>
        );

    return (
        <div ref={containerRef} className="adaptive-buttons-container">
            {isCalculationInProgress && (
                <div className="adaptive-buttons-width-computer">
                    {buttonsFiltered.map((button, index) => (
                        <span key={index} ref={(ref) => ref && (buttonsRef.current[index] = ref)}>
                            {button}
                        </span>
                    ))}
                </div>
            )}
            {buttonsFiltered.slice(0, buttonsShown).map((button, index) => (
                <span key={index}>{button}</span>
            ))}
            <span className="adaptive-buttons-spacer" />
            <span
                className={buttonsShown === buttonsFiltered.length ? 'adaptive-buttons-menu-button_transparent' : ''}
                ref={menuButtonRef}
            >
                {redesign ? (
                    <>
                        <MagritteButton
                            mode="secondary"
                            aria-label={trls[TrlKeys.showMore]}
                            onClick={toggleMenu}
                            hideLabel
                            icon={<EllipsisHorizontalOutlinedSize24 initial="primary" />}
                            ref={actionListActivatorRef}
                            data-qa="adaptive-buttons-menu-button"
                        >
                            {trls[TrlKeys.showMore]}
                        </MagritteButton>
                        <ActionList visible={menuIsOpen} onClose={closeDrop} dropProps={dropProps}>
                            <VSpacingContainer base={2}>{menuElements.map((element) => element)}</VSpacingContainer>
                        </ActionList>
                    </>
                ) : (
                    oldMenu
                )}
            </span>
        </div>
    );
};

export default translation(AdaptiveButtons);
